import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Checkbox, Input } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getFeatures, getProjects, ProjectInfo } from 'api';
import { ReactComponent as Close } from 'assets/images/home/close.svg';
import { ReactComponent as Discord } from 'assets/images/home/discord.svg';
import filterIcon from 'assets/images/home/filter.svg';
import logo from 'assets/images/home/logo.svg';
import { ReactComponent as Twitter } from 'assets/images/home/twitter.svg';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import { debounce } from 'utils/debounce';

export const Index: FC = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [features, setFeatures] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [projects, setProjects] = useState<ProjectInfo[]>([]);
  const [freeOnly, setFreeOnly] = useState<boolean>(false);
  const inputDom = useRef<any>(null);

  useEffect(() => {
    getFeatures().then((res: any) => {
      setFeatures(res.items);
    });
    getProjects({ page: 1, pageSize: 50 }).then(res => {
      setProjects(res.items);
      setTotalCount(res.count);
    });
  }, []);

  useEffect(() => {
    const keyword = inputDom.current !== null ? inputDom.current.input.value : '';
    getProjects({ page: 1, pageSize: 50, keyword, features: selected, freeOnly }).then(res => {
      setProjects(res.items);
      setTotalCount(res.count);
    });
  }, [selected, freeOnly]);

  const handleClick = (v: string) => {
    const res = [...selected];
    const index = res.indexOf(v);
    if (index >= 0) {
      res.splice(index, 1);
    } else {
      res.push(v);
    }
    setSelected(res);
  };

  const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    getProjects({
      page: 1,
      pageSize: 50,
      keyword: e.target.value,
      features: selected,
      freeOnly,
    }).then(res => {
      setProjects(res.items);
      setTotalCount(res.count);
    });
  }, 1000);

  const fetchMoreProjects = () => {
    if (projects.length >= totalCount) {
      return;
    }
    const keyword = inputDom.current !== null ? inputDom.current.input.value : '';
    getProjects({ page: page + 1, pageSize: 50, keyword, features: selected, freeOnly }).then(
      res => {
        setProjects(projects.concat(res.items));
        setTotalCount(res.count);
        setPage(page + 1);
      },
    );
  };

  return (
    <div>
      <div className="fixed left-0 top-0 right-0 z-[100] flex h-[80px] items-center bg-black">
        <div className="w-[240px] pl-[35px]">
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>
        <div className="ml-[30px] flex items-center gap-[46px]">
          <Link to="/get-listed">Get Listed</Link>
          <Input
            ref={inputDom}
            prefix={<SearchOutlined className="text-gray2" />}
            placeholder="Search project here"
            className="ml-[30px] h-[40px] w-[454px] rounded-[10px] border-[transparent] bg-gray font-normal"
            onChange={e => handleChange(e)}
          />
        </div>
      </div>
      <div className="fixed left-0 top-[80px] bottom-0 w-[240px] overflow-auto rounded-tr-[20px] bg-gray py-[40px] pl-[40px]">
        <div className="flex items-center gap-[4px]">
          <img src={filterIcon} />
          <span className="font-semibold">Items Filter</span>
        </div>
        <div className="my-[26px] text-gray3">Price</div>
        <Checkbox
          className={`font-inter text-[12px] font-semibold text-gray2 duration-300 hover:text-green ${
            freeOnly ? 'text-green' : ''
          }`}
          onChange={e => setFreeOnly(e.target.checked)}
          checked={freeOnly}
        >
          Free Only
        </Checkbox>
        <div className="my-[26px] text-gray3">Features</div>
        <div className="flex flex-col items-start gap-[20px]">
          {features.map((feature, i) => (
            <Checkbox
              className={`!ml-0 w-full font-inter text-[12px] font-semibold text-gray2 duration-300 hover:text-green ${
                selected.indexOf(feature) >= 0 ? 'text-green' : ''
              }`}
              key={i}
              checked={selected.indexOf(feature) >= 0}
              onClick={() => handleClick(feature)}
            >
              {feature}
            </Checkbox>
          ))}
        </div>
      </div>
      <div className="ml-[240px] mt-[80px] p-[30px]">
        <div className="mb-[16px] flex items-start">
          {selected.length > 0 ? (
            <div className="flex max-w-[70%] flex-wrap gap-[16px]">
              <Button className="rounded-[6px]" color="red" onClick={() => setSelected([])}>
                Clear
              </Button>
              {selected.map(v => (
                <Button key={v} className="shrink-0 rounded-[6px]" onClick={() => handleClick(v)}>
                  <span>{v}</span>
                  <Close className="ml-[4px] h-[8px] w-auto" />
                </Button>
              ))}
            </div>
          ) : null}
          <div className="ml-auto font-inter">{totalCount} results</div>
        </div>
        {projects.length > 0 ? (
          <InfiniteScroll
            className="flex w-full flex-wrap gap-[20px]"
            dataLength={projects.length} //This is important field to render the next data
            next={fetchMoreProjects}
            hasMore={projects.length < totalCount}
            loader={null}
          >
            {projects.map((project, i) => (
              <div
                key={i}
                className="relative bottom-0 flex w-[256px] cursor-pointer items-center rounded-[10px] bg-gray px-[16px] py-[24px] duration-300 hover:bottom-[2px] hover:bg-gray4"
                onClick={() => window.open(project.website)}
              >
                <img src={project.logo} className="mr-[12px] h-[60px] w-[60px] rounded-[16px]" />
                <div className="flex h-full flex-col justify-between">
                  <div className="flex items-center gap-[4px]">
                    <div className="text-[16px]">{project.project_name}</div>
                    {project.free_now ? (
                      <div className="rounded-full bg-green px-[6px] text-[12px] text-white">
                        Free
                      </div>
                    ) : null}
                  </div>
                  <div className="flex items-center gap-[8px]">
                    {project.twitter_url != undefined ? (
                      <Link className="h-[20px] w-[20px] rounded-full bg-black hover:bg-twitterBlue">
                        <Twitter />
                      </Link>
                    ) : null}
                    {project.discord_url != undefined ? (
                      <Link className="h-[20px] w-[20px] rounded-full bg-black hover:bg-discordBlue">
                        <Discord />
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div className="py-[100px] text-center text-[25px] font-semibold text-gray2">
            No project to display
          </div>
        )}
      </div>
    </div>
  );
};
