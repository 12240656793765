import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GetListed } from './pages/get-listed';
import { Index } from './pages/index';
import { Index as NotFound } from './pages/not-found';

export const App: FC = () => {
  return (
    <Routes>
      <Route index element={<Index />} />
      <Route path="get-listed" element={<GetListed />} />
      <Route path="not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </Routes>
  );
};
