import { FC } from 'react';
import notFound from 'assets/images/not-found/not-found.svg';

export const Index: FC = () => {
  return (
    <div className="flex flex-col items-center p-[40px]">
      <img src={notFound} />
      <div className="mt-[10px] text-[40px] font-medium text-gray2">OOPS!</div>
      <div className="text-[20px] text-gray2">It looks like you‘re lost...</div>
    </div>
  );
};
