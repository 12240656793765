import { Checkbox, Input, message, Spin, Switch, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useFormik } from 'formik';
import { NFTStorage } from 'nft.storage';
import { FC, useEffect, useState } from 'react';
import { getFeatures } from 'api';
import pLogo from 'assets/images/get-listed/project-logo.svg';
import uploadIcon from 'assets/images/get-listed/upload-icon.svg';
import { ReactComponent as Discord } from 'assets/images/home/discord.svg';
import logo from 'assets/images/home/logo.svg';
import { ReactComponent as Twitter } from 'assets/images/home/twitter.svg';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import { NFT_STORAGE_TOKEN } from 'utils/config';

interface InitialData {
  name: string;
  logo: string;
  website: string;
  twitter: string;
  discord: string;
  freeNow: boolean;
  features: string[];
  other: string;
}

const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });

export const GetListed: FC = () => {
  const [features, setFeatures] = useState<string[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    getFeatures().then((res: any) => {
      setFeatures(res.items);
    });
  }, []);

  const { values, setFieldValue, handleChange, handleSubmit, setValues } = useFormik<InitialData>({
    initialValues: {
      name: '',
      logo: '',
      website: '',
      twitter: '',
      discord: '',
      freeNow: false,
      features: [],
      other: '',
    },
    onSubmit: () => {
      fetch('https://sheet.best/api/sheets/d9d896cd-2f2c-495c-a0be-724e7d1387b7', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }).then(() => {
        setValues({
          name: '',
          logo: '',
          website: '',
          twitter: '',
          discord: '',
          freeNow: false,
          features: [],
          other: '',
        });
        message.success('Submit Success!');
      });
    },
  });

  const selectFeature = (v: string) => {
    const res = [...values.features];
    const index = res.indexOf(v);
    if (index >= 0) {
      res.splice(index, 1);
    } else {
      res.push(v);
    }
    setFieldValue('features', res);
  };

  const upload = (file: RcFile) => {
    setUploading(true);
    client
      .store({
        name: file.name,
        description: 'project logo',
        image: file,
      })
      .then(metadata => {
        message.success('Upload Success!');
        setUploading(false);
        setFieldValue(
          'logo',
          `https://ipfs.io/ipfs/${metadata?.data?.image?.pathname?.substring(2)}`,
        );
      });
  };

  return (
    <div>
      <div className="fixed left-0 top-0 right-0 flex h-[80px] items-center">
        <div className="w-[240px] pl-[35px]">
          <Link type="image" to="/">
            <img src={logo} />
          </Link>
        </div>
        <div className="ml-[30px] flex items-center gap-[46px]">
          <Link type="text" to="/get-listed">
            Get Listed
          </Link>
        </div>
      </div>
      <div className="mt-[110px] flex justify-center">
        <div className="inline-block rounded-[10px] bg-gray p-[24px]">
          <form autoComplete="off">
            <div className="flex h-[64px] items-center">
              <Upload
                name="upload"
                customRequest={({ file }) => upload(file as RcFile)}
                showUploadList={false}
                accept=".png, .jpg, .jpeg, .gif"
              >
                {values.logo === '' ? (
                  <div className="relative h-[64px] w-[64px]">
                    {/* <img src={pLogo} className="mr-[12px] h-[64px] w-[64px] rounded-[16px]" /> */}
                    <img src={uploadIcon} className="mr-[12px] h-[64px] w-[64px] rounded-[16px]" />
                    {uploading ? (
                      <Spin className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                    ) : null}
                  </div>
                ) : (
                  <div className="relative h-[64px] w-[64px]">
                    <img src={values.logo} className="mr-[12px] h-[64px] w-[64px] rounded-[16px]" />
                    {uploading ? (
                      <Spin className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                    ) : null}
                  </div>
                )}
              </Upload>
              <div className="ml-[20px] flex h-full flex-col justify-between">
                <div className="flex items-center gap-[4px]">
                  <div className="text-[16px]">{values.name}</div>
                  {values.freeNow ? (
                    <div className="rounded-full bg-green px-[6px] text-[12px] text-white">
                      Free
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center gap-[8px]">
                  {values.twitter !== '' ? (
                    <Link className="h-[20px] w-[20px] rounded-full bg-black hover:bg-twitterBlue">
                      <Twitter />
                    </Link>
                  ) : null}
                  {values.discord !== '' ? (
                    <Link className="h-[20px] w-[20px] rounded-full bg-black hover:bg-discordBlue">
                      <Discord />
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex gap-[40px]">
              <div className="w-[368px]">
                <div className="flex h-[57px] items-center justify-between border-b-[1px] border-gray6">
                  <div className="shrink-0 text-[15px] text-gray5">Project Name</div>
                  <Input
                    placeholder="Project Name"
                    bordered={false}
                    className="text-right text-[15px]"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </div>
                <div className="flex h-[57px] items-center justify-between border-b-[1px] border-gray6">
                  <div className="shrink-0 text-[15px] text-gray5">Official Website</div>
                  <Input
                    placeholder="Official Website"
                    bordered={false}
                    className="text-right text-[15px]"
                    name="website"
                    onChange={handleChange}
                    value={values.website}
                  />
                </div>
                <div className="flex h-[57px] items-center justify-between border-b-[1px] border-gray6">
                  <div className="shrink-0 text-[15px] text-gray5">Twitter</div>
                  <Input
                    placeholder="Twitter"
                    bordered={false}
                    className="text-right text-[15px]"
                    name="twitter"
                    onChange={handleChange}
                    value={values.twitter}
                  />
                </div>
                <div className="flex h-[57px] items-center justify-between border-b-[1px] border-gray6">
                  <div className="shrink-0 text-[15px] text-gray5">Discord</div>
                  <Input
                    bordered={false}
                    placeholder="Discord"
                    className="text-right text-[15px]"
                    name="discord"
                    onChange={handleChange}
                    value={values.discord}
                  />
                </div>
                <div className="flex h-[57px] items-center justify-between border-b-[1px] border-gray6">
                  <div className="shrink-0 text-[15px] text-gray5">Free</div>
                  <Switch
                    className="my-switch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={value => setFieldValue('freeNow', value)}
                    checked={values.freeNow}
                  />
                </div>
              </div>
              <div className="w-[368px]">
                <div className="mb-[25px] text-[16px] text-gray5">Features</div>
                <div className="grid max-h-[175px] grid-cols-2 overflow-auto">
                  {features.map((feature, i) => (
                    <Checkbox
                      className={`!ml-0 mb-[30px] font-inter text-[12px] font-semibold text-gray2 duration-300 hover:text-green ${
                        values.features.indexOf(feature) >= 0 ? 'text-green' : ''
                      }`}
                      key={i}
                      checked={values.features.indexOf(feature) >= 0}
                      onClick={() => selectFeature(feature)}
                    >
                      {feature}
                    </Checkbox>
                  ))}
                </div>
                <Input.TextArea
                  placeholder="List other features of your project"
                  bordered={false}
                  className="w-full rounded-[5px] !bg-black text-[12px] font-semibold"
                  name="other"
                  onChange={handleChange}
                  value={values.other}
                />
              </div>
            </div>
          </form>
          <div className="mt-[20px] flex items-center justify-center gap-[8px]">
            <Button
              color="red"
              className="h-[48px] w-[180px] rounded-[12px] text-[15px] font-semibold"
              onClick={() =>
                setValues({
                  name: '',
                  logo: '',
                  website: '',
                  twitter: '',
                  discord: '',
                  freeNow: false,
                  features: [],
                  other: '',
                })
              }
            >
              Clear
            </Button>
            <Button
              color="green"
              className="h-[48px] w-[180px] rounded-[12px] text-[15px] font-semibold"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
