import { ComponentProps, ComponentRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends Omit<ComponentProps<'button'>, 'type'> {
  type?: 'filled' | 'outline';
  color?: 'red' | 'gray' | 'black' | 'green';
  size?: 'sm' | 'md' | 'lg';
}

export const Button = forwardRef<ComponentRef<'button'>, Props>(
  (
    {
      className,
      type = 'outline',
      color = 'gray',
      size = 'sm',
      disabled = false,
      children,
      ...props
    },
    ref,
  ) => {
    const finalDisabled = disabled;

    let baseClassName = `inline-flex cursor-pointer items-center justify-center bg-transparent px-0 py-0 outline-0 transition-all duration-300 ${
      finalDisabled ? 'cursor-not-allowed' : ''
    }`;

    switch (size) {
      case 'sm':
        switch (type) {
          case 'filled':
          case 'outline':
            baseClassName += ' h-[36px] rounded-full border px-[22px] text-[14px]';
            break;
        }
        break;
      case 'md':
        switch (type) {
          case 'filled':
          case 'outline':
            baseClassName += ' h-[44px] rounded-full border px-[26px] text-[18px]';
            break;
        }
        break;
      case 'lg':
        switch (type) {
          case 'filled':
          case 'outline':
            baseClassName += ' h-[56px] rounded-full border px-[32px] text-[24px]';
            break;
        }
        break;
    }

    switch (color) {
      case 'red':
        switch (type) {
          case 'filled':
            baseClassName += ` border-transparent bg-red text-white ${
              finalDisabled ? 'bg-gray text-white' : 'hover:bg-red2'
            }`;
            break;
          case 'outline':
            baseClassName += ` border-red text-red ${
              finalDisabled
                ? 'border-transparent bg-gray text-white'
                : 'hover:border-transparent hover:bg-red hover:text-white'
            }`;
            break;
        }
        break;
      case 'gray':
        switch (type) {
          case 'filled':
            baseClassName += ` border-transparent bg-gray2 text-white ${
              finalDisabled ? 'bg-gray text-white' : 'hover:bg-blue'
            }`;
            break;
          case 'outline':
            baseClassName += ` border-gray2 text-gray2 ${
              finalDisabled
                ? 'border-transparent bg-gray text-white'
                : 'hover:border-transparent hover:bg-gray2 hover:text-white'
            }`;
            break;
        }
        break;
      case 'black':
        switch (type) {
          case 'filled':
            baseClassName += ` border-transparent bg-black text-white ${
              finalDisabled ? 'bg-gray text-white' : 'hover:bg-blue'
            }`;
            break;
          case 'outline':
            baseClassName += ` border-black text-black ${
              finalDisabled
                ? 'border-transparent bg-gray text-white'
                : 'hover:border-transparent hover:bg-black hover:text-white'
            }`;
            break;
        }
        break;
      case 'green':
        switch (type) {
          case 'filled':
            baseClassName += ` border-transparent bg-green text-white ${
              finalDisabled ? 'bg-gray text-white' : 'hover:bg-blue'
            }`;
            break;
          case 'outline':
            baseClassName += ` border-green text-green ${
              finalDisabled
                ? 'border-transparent bg-gray text-white'
                : 'hover:border-transparent hover:bg-green hover:text-white'
            }`;
            break;
        }
        break;
    }

    return (
      <button
        ref={ref}
        className={twMerge(baseClassName, className)}
        type="button"
        disabled={finalDisabled}
        {...props}
      >
        {children}
      </button>
    );
  },
);
