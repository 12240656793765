import queryString from 'query-string';
import { BACKEND_URL } from 'utils/config';

interface GetProjectsQuery {
  page: number;
  pageSize: number;
  keyword?: string;
  features?: string[];
  freeOnly?: boolean;
}

interface GetFeaturesResponse {
  count: number;
  items: string[];
}

export interface ProjectInfo {
  project_name: string;
  logo: string;
  free_now: boolean;
  website: string;
  twitter_url?: string;
  discord_url?: string;
  features: string[];
}

interface GetProjectsResponse {
  count: number;
  items: ProjectInfo[];
}

const getFeatures = (): Promise<GetFeaturesResponse> => {
  return fetch(`${BACKEND_URL}/features`, { method: 'GET' }).then(res => res.json());
};

const getProjects = (query: GetProjectsQuery): Promise<GetProjectsResponse> => {
  const param = query;
  if (!(query.freeOnly ?? false)) {
    delete param.freeOnly;
  }
  const queryParams = queryString.stringify(param);
  return fetch(`${BACKEND_URL}/projects?${queryParams}`, { method: 'GET' }).then(res => res.json());
};

export { getFeatures, getProjects };
