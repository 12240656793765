import { ComponentPropsWithoutRef, ComponentRef } from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type Ref = ComponentRef<'a'> | ComponentRef<typeof RouterLink>;

type Props = ComponentPropsWithoutRef<'a'> | ComponentPropsWithoutRef<typeof RouterLink>;

export const Link = forwardRef<Ref, Props>(({ className, onClick, children, ...props }, ref) => {
  const baseClassName =
    'inline-flex justify-center items-center no-underline transition-all duration-300';

  return 'to' in props && props.to != undefined ? (
    <RouterLink
      ref={ref}
      className={twMerge(baseClassName, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </RouterLink>
  ) : (
    <a
      ref={ref}
      className={twMerge(baseClassName, className)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
});
